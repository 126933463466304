import React, { useState, useEffect } from 'react';

import Loading from "../../Components/Loading"
import api from '../../Services/api'

function FormFile({ history }) {
    const [arquivo, setArquivo] = useState('');
    const [folder_id, setFolder_id] = useState('');
    const [folders, setFolders] = useState([]);
    const [loading, setLoading] = useState(0);

    useEffect(() => {
        async function getFolders() {
            const response = await api.get(`/folder`);
            setFolders(response.data);
        }
        getFolders();
    }, []);

    async function hadleSubmit(e) {
        e.preventDefault();
        setLoading(1);
        if(folder_id){
            const formData = new FormData();
            formData.append("folder_id", folder_id);
            formData.append("arquivo", arquivo);
            try {
                await api.post("/file", formData);
                history.replace('/file');
            } catch (e) {
                console.log(e.response);
                setLoading(0);
            }
        }
    }

    return (
        <div className="container">
            <strong>Adicionar Arquivos</strong>
            <div className="header">
            </div>
            <div className="painel">
                <form onSubmit={hadleSubmit}>
                    <div className="input-block">
                        <label htmlFor="arquivo">Arquivo</label>
                        <input type="file"
                            name="arquivo" id="arquivo"
                            onChange={e => setArquivo(e.target.files[0])}
                            required
                        />
                    </div>
                    <div className="input-block">
                        <label htmlFor="folder_id">Pasta</label>
                        <select
                            id="folder_id"
                            name="folder_id"
                            required
                            value={folder_id}
                            onChange={e => setFolder_id(e.target.value)}
                        >
                            <option value>Selecione um pasta...</option>
                            {
                                folders.map(folder => (
                                    <option key={folder.id} value={folder.id}>{folder.name}</option>
                                ))
                            }
                        </select>
                    </div>

                    <button type="submit">Salvar</button>
                </form>
            </div>
            {loading ? <Loading /> : null}
        </div>
    );
}

export default FormFile;