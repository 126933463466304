import React, { useState } from 'react';

import Loading from "../../Components/Loading";
import api from '../../Services/api';

function FormChurch({ history }) {
    const [title, setTitle] = useState('');
    const [description, setDescription] = useState('');
    const [latitude, setLatitude] = useState('');
    const [longitude, setLongitude] = useState('');
    const [loading, setLoading] = useState(0);


    async function hadleSubmit(e){
        e.preventDefault();
        setLoading(1);
        try{
            await api.post("/church", {
                title,
                description,
                latitude,
                longitude
            });
            history.push('/church');
        }catch(e){
            setLoading(0);
        }
    }

    return (
        <div className="container">
            <strong>Adicionar igreja</strong>
            <div className="header">
            </div>
            <div className="painel">
                <form onSubmit={hadleSubmit}>
                        <div className="input-block">
                            <label htmlFor="title">Título</label>
                            <input
                                type="text"
                                value={title}
                                name="title" id="title"
                                required
                                onChange={e => setTitle(e.target.value)}
                            />
                        </div>
                    <div className="input-group">
                        <div className="input-block">
                            <label htmlFor="latitude">latitude</label>
                            <input
                                type="text"
                                value={latitude}
                                name="latitude" id="latitude"
                                required
                                onChange={e => setLatitude(e.target.value)}
                            />
                        </div>
                        <div className="input-block">
                            <label htmlFor="longitude">longitude</label>
                            <input
                                type="text"
                                name="longitude"
                                id="longitude"
                                required
                                value={longitude}
                                onChange={e => setLongitude(e.target.value)}
                            />
                        </div>
                    </div>
                    <div className="input-block">
                        <label htmlFor="description">Descrição</label>
                        <textarea rows="8" name="description" id="description" value={description} required onChange={e => setDescription(e.target.value)}></textarea>
                    </div>
                    <button type="submit">Salvar</button>
                </form>
            </div>
            {loading ? <Loading /> : null}
        </div>
    );
}

export default FormChurch;