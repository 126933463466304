import React from 'react';
import Header from '../../Components/Header';
import Sidebar from '../../Components/Sidebar';
import Body from '../../Components/Body';
import { BrowserRouter } from 'react-router-dom';

import './style.css';

function Main() {
    return (
        <BrowserRouter>
            <div id="main">
                <Header />
                <div id="body">
                    <Sidebar />
                    <Body />
                </div>

            </div>
        </BrowserRouter>
    );
}

export default Main;