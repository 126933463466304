import React from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';

import './global.css';
import './App.css';
import Login from './Login';
import Main from './Main';

function App() {
    return (
        <BrowserRouter>
            <Main />
            {/*<Switch>
                <Route exact path='/' component={Login} />
                <Route exact path='/main' component={Main} />
            </Switch>*/}
        </BrowserRouter>
    );
}

export default App;
