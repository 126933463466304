import React, { useState, useMemo } from 'react';
import { FaCamera } from "react-icons/fa";

import Loading from "../../Components/Loading";
import api from '../../Services/api';

function FormChurch({ history }) {
    const [name, setName] = useState('');
    const [image, setImage] = useState(null);
    const [loading, setLoading] = useState(0);

    const preview = useMemo( () => {
        return image ? URL.createObjectURL(image) : null;
    }, [image]);

    async function hadleSubmit(e){
        e.preventDefault();
        setLoading(1);
        const formData = new FormData();
        formData.append("name", name);
        formData.append("photograph", image);
        try{
            await api.post("/Missionary", formData);
            history.push('/missionary');
        }catch(e){
            setLoading(0);
        }
    }

    return (
        <div className="container">
            <strong>Adicionar missionário</strong>
            <div className="header">
            </div>
            <div className="painel">
                <form onSubmit={hadleSubmit}>
                <div className="input-block" style={{display: 'flex', justifyContent: 'center'}}>
                        <label id="thumbnail" style={{backgroundImage : `url(${preview})`, width: '130px'}}>
                            <input type="file" 
                                onChange={e => setImage(e.target.files[0])}
                            />
                            <FaCamera />
                        </label>
                    </div>
                        <div className="input-block">
                            <label htmlFor="name">Título</label>
                            <input
                                type="text"
                                value={name}
                                name="name" id="name"
                                required
                                onChange={e => setName(e.target.value)}
                            />
                        </div>
                    <button type="submit">Salvar</button>
                </form>
            </div>
            {loading ? <Loading /> : null}
        </div>
    );
}

export default FormChurch;