import React, { useState } from 'react';
import Loading from "../../Components/Loading"
import api from '../../Services/api'

function FormFolder({history}) {
    const [name, setName] = useState('');
    const [loading, setLoading] = useState(0);

    async function hadleSubmit(e){
        e.preventDefault();
        setLoading(1);
        try{ 
            await api.post("/folder", {
                name
            });
            history.push('/folder');
        }catch(e){
            setLoading(0);
        }
    }

    return (
        <div className="container">
            <strong>Adicionar Pasta</strong>
            <div className="header">
            </div>
            <div className="painel">
                <form onSubmit={hadleSubmit}>

                        <div className="input-block">
                            <label htmlFor="name">Nome</label>
                            <input
                                type="text"
                                value={name}
                                name="name" id="name"
                                required
                                onChange={e => setName(e.target.value)}
                            />
                        </div>
                    <button type="submit">Salvar</button>
                </form>
            </div>
            {loading ? <Loading /> : null}
        </div>
    );
}

export default FormFolder;