import React from 'react';
import Routes from '../../routes';
import './style.css';

function Body(){
    return(
        <main>
            <Routes />
        </main>
    );
}

export default Body;