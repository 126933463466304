import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { FaEdit, FaTrashAlt } from "react-icons/fa";

import api from '../../Services/api'
import './style.css';

function News() {
    const [news, SetNews] = useState([]);

    useEffect(() => {
        async function getNews() {
            const response = await api.get('/news');
            SetNews(response.data);
        }
        getNews();
    }, []);

    return (
        <div className="container">
            <strong>Noticias</strong>
            <div className="header">
                <Link to="/news/form">
                    <button>Adicionar</button>
                </Link>
            </div>
            <div className="painel">
                <table>
                    <thead>
                        <tr>
                            <th>Id</th>
                            <th>Título</th>
                            <th>Opções</th>
                        </tr>
                    </thead>
                    <tbody>
                        {news.map(news => (
                            <tr key={news.id}>
                                <td>{news.id}</td>
                                <td>{news.title}</td>
                                <td className="options">
                                    <a><FaEdit /></a>
                                    <a><FaTrashAlt /></a>
                                </td>
                            </tr>)
                        )}
                    </tbody>
                </table>
            </div>
        </div>
    );
}

export default News;