import React, { useState, useMemo } from 'react';

import { FaCamera } from "react-icons/fa";

import Loading from "../../Components/Loading"
import api from '../../Services/api'
import './style.css';

function FormNews({ history }) {
    const [title, setTitle] = useState('');
    const [subtitle, setSubtitle] = useState('');
    const [text, setText] = useState('');
    const [image, setImage] = useState(null);
    const [loading, setLoading] = useState(0);

    const preview = useMemo( () => {
        return image ? URL.createObjectURL(image) : null;
    }, [image]);

    async function hadleSubmit(e){
        e.preventDefault();
        setLoading(1);
        const formData = new FormData();
        formData.append("title", title);
        formData.append("subtitle", subtitle);
        formData.append("text", text);
        formData.append("image", image);
        try{
            await api.post("/news", formData);
            history.push('/news');
        }catch(e){
            setLoading(0);
        }
    }

    return (
        <div className="container">
            <strong>Adcionar Noticias</strong>
            <div className="header">
            </div>
            <div className="painel">
                <form onSubmit={hadleSubmit}>
                    <div className="input-block" style={{display: 'flex', justifyContent: 'center'}}>
                        <label id="thumbnail" style={{backgroundImage : `url(${preview})`}}>
                            <input type="file" 
                                onChange={e => setImage(e.target.files[0])}
                            />
                            <FaCamera />
                        </label>
                    </div>
                    <div className="input-group">
                        <div className="input-block">
                            <label htmlFor="title">Título</label>
                            <input
                                type="text"
                                value={title}
                                name="title" id="title"
                                required
                                onChange={e => setTitle(e.target.value)}
                            />
                        </div>
                        <div className="input-block">
                            <label htmlFor="subtitle">Subtítulo</label>
                            <input
                                type="text"
                                name="subtitle"
                                id="subtitle"
                                required
                                value={subtitle}
                                onChange={e => setSubtitle(e.target.value)}
                            />
                        </div>
                    </div>
                    <div className="input-block">
                        <label htmlFor="">Texto</label>
                        <textarea rows="8" name="text" id="text" value={text} required onChange={e => setText(e.target.value)}></textarea>
                    </div>
                    <button type="submit">Salvar</button>
                </form>
            </div>
            {loading ? <Loading /> : null}
        </div>
    );
}

export default FormNews;