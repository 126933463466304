import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { FaEdit, FaTrashAlt } from "react-icons/fa";

import api from '../../Services/api'
import './style.css';

function MissionaryProject() {
    const [missionaryProject, SetMissionaryProject] = useState([]);

    useEffect(() => {
        async function getMissionaryProject() {
            const response = await api.get('/MissionaryProject');
            SetMissionaryProject(response.data);
        }
        getMissionaryProject();
    }, []);

    return (
        <div className="container">
            <strong>Projetos missionarios</strong>
            <div className="header">
                <Link to="/missionaryProject/form">
                    <button>Adicionar</button>
                </Link>
            </div>
            <div className="painel">
                <table>
                    <thead>
                        <tr>
                            <th>Id</th>
                            <th>Título</th>
                            <th>Opções</th>
                        </tr>
                    </thead>
                    <tbody>
                        {missionaryProject.map(missionaryProject => (
                            <tr key={missionaryProject.id}>
                                <td>{missionaryProject.id}</td>
                                <td>{missionaryProject.title}</td>
                                <td className="options">
                                    <a><FaEdit /></a>
                                    <a><FaTrashAlt /></a>
                                </td>
                            </tr>)
                        )}
                    </tbody>
                </table>
            </div>
        </div>
    );
}

export default MissionaryProject;