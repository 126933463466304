import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { FaEdit, FaTrashAlt } from "react-icons/fa";

import { formatDate } from '../../Util/index';

import api from '../../Services/api'


function Calendar() {
    const [calendar, SetNews] = useState([]);

    useEffect(() => {
        async function getNews() {
            const response = await api.get('/calendar');
            SetNews(response.data);
        }
        getNews();
    }, []);

    return (
        <div className="container">
            <strong>Calendário</strong>
            <div className="header">
                <Link to="/calendar/form">
                    <button>Adicionar</button>
                </Link>
            </div>
            <div className="painel">
                <table>
                    <thead>
                        <tr>
                            <th>Id</th>
                            <th>Descrição</th>
                            <th>Data</th>
                            <th>Opções</th>
                        </tr>
                    </thead>
                    <tbody>
                        {calendar.map(calendar => (
                            <tr key={calendar.id}>
                                <td>{calendar.id}</td>
                                <td>{calendar.description}</td>
                                <td>{formatDate(calendar.date)}</td>
                                <td className="options">
                                    <a href="#"><FaEdit /></a>
                                    <a href="#"><FaTrashAlt /></a>
                                </td>
                            </tr>)
                        )}
                    </tbody>
                </table>
            </div>
        </div>
    );
}

export default Calendar;