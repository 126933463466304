import React, {useState, useEffect} from 'react';
import { FaEdit, FaTrashAlt } from "react-icons/fa";
import { Link } from 'react-router-dom';

import api from '../../Services/api'
import './style.css';

function Folder() {
    const [folder, SetFolder] = useState([]);
    
    useEffect( () =>{
        async function getFolder(){
            const response = await api.get('/folder');
            SetFolder(response.data);
        }
        getFolder();
    },[]);
    
    return (
        <div className="container">
            <strong>Pastas</strong>
            <div className="header">
                <Link to="/folder/form">
                    <button>Adicionar</button>
                </Link>
            </div>
            <div className="painel">
                <table>
                    <thead>
                        <tr>
                            <th>Id</th>
                            <th>Nome</th>
                            <th>Opções</th>
                        </tr>
                    </thead>
                    <tbody>
                        {folder.map(folder => (                        
                            <tr key={folder.id}>
                                <td>{folder.id}</td>
                                <td>{folder.name}</td>
                                <td className="options">
                                    <a href="#"><FaEdit /></a>
                                    <a href="#"><FaTrashAlt /></a>
                                </td>
                            </tr>)
                        )}
                    </tbody>
                </table>
            </div>
        </div>
    );
}

export default Folder;