import React, {useState} from 'react';
import { Link } from 'react-router-dom';
import { FaEdit, FaTrashAlt } from "react-icons/fa";
import './style.css';

function Sidebar(){
    const [width, setWidth] = useState({});
    return(
        <aside id="sidebar" style={width}>
            <ul>

                <li>
                    <Link to="/news">
                        <FaEdit /><span>Notícias</span>
                    </Link>
                </li>
                <li>
                    {/*<span>Materiais</span>*/}
                    <ul>
                        <li>
                            <Link to="/folder">
                                <FaEdit /><span>Pastas</span>
                            </Link>
                        </li>
                        <li>
                            <Link to="/file">
                                <FaEdit /><span>Arquivos</span>
                            </Link>
                        </li>
                    </ul>
                </li>
                <li>
                    <Link to="/missionary">
                        <FaEdit /><span>Missionarios</span>
                    </Link>
                </li>
                <li>
                    <Link to="/missionaryProject">
                        <FaEdit /><span>Projetos Missionarios</span>
                    </Link>
                </li>
                <li>
                    <Link to="/church">
                        <FaEdit /><span>Igrejas</span>
                    </Link>
                </li>
                <li>
                    <Link to="/calendar">
                        <FaEdit /><span>Calendario</span>
                    </Link>
                </li>
                <li>
                    <Link to="/notification">
                        <FaEdit /><span>Notificação</span>
                    </Link>
                </li>

            </ul>
        </aside>
    );

    function openMenu(){
        setWidth({width : "280px"});   
    }

}

export default Sidebar;