import React from 'react';
import { Route, Switch } from 'react-router-dom';

import News from './Pages/News';
import FormNews from './Pages/News/form';

import Folder from './Pages/Folder';
import FormFolder from './Pages/Folder/form';

import File from './Pages/File';
import FormFile from './Pages/File/form';

import Calendar from './Pages/Calendar';
import FormCalendar from './Pages/Calendar/form';

import Church from './Pages/Church';
import FormChurch from './Pages/Church/form';

import Missionary from './Pages/Missionary';
import FormMissionary from './Pages/Missionary/form';

import MissionaryProject from './Pages/MissionaryProject';
import FormMissionaryProject from './Pages/MissionaryProject/form';

import Notification from './Pages/Notification';
function Routes(){ 
    return(
        <Switch>
            <Route exact path="/" component={() => (<h1>Home</h1>)} />
            <Route exact path="/news" component={News} />
            <Route exact path="/news/form" component={FormNews} />
            <Route exact path="/folder" component={Folder} />
            <Route exact path="/folder/form" component={FormFolder} />
            <Route exact path="/file" component={File} />
            <Route exact path="/file/form" component={FormFile} />
            <Route exact path="/calendar" component={Calendar} />
            <Route exact path="/calendar/form" component={FormCalendar} />
            <Route exact path="/church" component={Church} />
            <Route exact path="/church/form" component={FormChurch} />
            <Route exact path="/missionary" component={Missionary} />
            <Route exact path="/missionary/form" component={FormMissionary} />
            <Route exact path="/missionaryProject" component={MissionaryProject} />
            <Route exact path="/missionaryProject/form" component={FormMissionaryProject} />
            <Route exact path="/notification" component={Notification} />
        </Switch>
    )
}

export default Routes;