import React, { useState, useMemo, useEffect } from 'react';
import Select from 'react-select';
import { FaCamera } from "react-icons/fa";

import Loading from "../../Components/Loading"
import api from '../../Services/api'
import './style.css';

function FormMissionaryProject({ history }) {
    const [title, setTitle] = useState('');
    const [missionario_id, setMissionario_id] = useState([]);
    const [missionarios, setMissionarios] = useState([]);
    const [text, setText] = useState('');
    const [image, setImage] = useState(null);
    const [loading, setLoading] = useState(0);
    const preview = useMemo(() => {
        return image ? URL.createObjectURL(image) : null;
    }, [image]);

    useEffect(() => {
        async function getMissionary() {
            var array = [];
            const response = await api.get(`/Missionary`);
            response.data.map(missionario => {
                array = [...array, {value: missionario.id, label: missionario.name}];
            });
            setMissionarios(array);
        }
        getMissionary();
    }, []);

    async function hadleSubmit(e) {
        e.preventDefault();
        setLoading(1);
        const formData = new FormData();
        formData.append("title", title);
        formData.append("text", text);
        formData.append("image", image);
        missionario_id.map(data => {
            formData.append("missionary_id[]", data.value);
        });
        try {
           await api.post("/MissionaryProject", formData);
           history.push('/missionaryProject');
        } catch (e) {
            setLoading(0);
        }
    }

    return (
        <div className="container">
            <strong>Adicionar Projeto</strong>
            <div className="header">
            </div>
            <div className="painel">
                <form onSubmit={hadleSubmit}>
                    <div className="input-block" style={{ display: 'flex', justifyContent: 'center' }}>
                        <label id="thumbnail" style={{ backgroundImage: `url(${preview})` }}>
                            <input type="file"
                                onChange={e => setImage(e.target.files[0])}
                            />
                            <FaCamera />
                        </label>
                    </div>

                    <div className="input-block">
                        <label htmlFor="title">Título</label>
                        <input
                            type="text"
                            value={title}
                            name="title" id="title"
                            required
                            onChange={e => setTitle(e.target.value)}
                        />
                    </div>
                    <div className="input-block">
                        <label htmlFor="missionario_id">Missionarios participantes</label>
                        <Select
                            id="missionario_id"
                            isMulti
                            options={missionarios}
                            value={missionario_id}
                            onChange={e => setMissionario_id(e)}
                        />
            
                    </div>

                    <div className="input-block">
                        <label htmlFor="">Texto</label>
                        <textarea rows="8" name="text" id="text" value={text} required onChange={e => setText(e.target.value)}></textarea>
                    </div>
                    <button type="submit">Salvar</button>
                </form>
            </div>
            {loading ? <Loading /> : null}
        </div>
    );
}

export default FormMissionaryProject;