import React, { useState } from 'react';

import Loading from '../../Components/Loading';
import api from '../../Services/api';

function Notification({history}) {
    const [title, setTitle] = useState('');
    const [description, setDescription] = useState('');
    const [url, setUrl] = useState('');
    const [loading, setLoading] = useState(0);

    async function hadleSubmit(e){
        e.preventDefault();
        setLoading(1);
        try{
            await api.post("/notification", {
                title,
                description,
                url
            });
            history.push('/notification');
        }catch(e){
            console.log(e.response);
            setLoading(0);
        }
    }

    return (
        <div className="container">
            <strong>Envia notificação</strong>
            <div className="header">
            </div>
            <div className="painel">
                <form onSubmit={hadleSubmit}>
                    <div className="input-block">
                        <label htmlFor="title">Título</label>
                        <input
                            type="text"
                            value={title}
                            name="title"
                            required
                            onChange={e => setTitle(e.target.value)}
                        />
                    </div>
                    <div className="input-block">
                        <label htmlFor="url">Url</label>
                        <input
                            type="text"
                            value={url}
                            name="url"
                            onChange={e => setUrl(e.target.value)}
                        />
                    </div>

                    <div className="input-block">
                        <label htmlFor="description">Descrição</label>
                        <textarea rows="8" name="description" value={description} required onChange={e => setDescription(e.target.value)}></textarea>
                    </div>
                    <button type="submit">Salvar</button>
                </form>
            </div>
            {loading ? <Loading /> : null}
        </div>
    )
}

export default Notification;