import React from 'react';

import Carregando from "../../Assets/carregando.gif"
import './style.css';

function Loading() {


    return (
        <div id="loading">
            <img src={Carregando} />
        </div>
    );
}

export default Loading;