import React, {useState, useEffect} from 'react';
import { FaEdit, FaTrashAlt } from "react-icons/fa";
import { Link } from 'react-router-dom';

import api from '../../Services/api'
import './style.css';

function File() {
    const [file, SetFile] = useState([]);
    
    useEffect( () =>{
        async function getFile(){
            const response = await api.get('/file');
            SetFile(response.data);
        }
        getFile();
    },[]);
    
    return (
        <div className="container">
            <strong>Arquivos</strong>
            <div className="header">
                <Link to="/file/form">
                    <button>Adicionar</button>
                </Link>
            </div>
            <div className="painel">
                <table>
                    <thead>
                        <tr>
                            <th>Id</th>
                            <th>Nome</th>
                            <th>Opções</th>
                        </tr>
                    </thead>
                    <tbody>
                        {file.map(file => (                        
                            <tr key={file.id}>
                                <td>{file.id}</td>
                                <td>{file.name}</td>
                                <td className="options">
                                    <a href="#"><FaEdit /></a>
                                    <a href="#"><FaTrashAlt /></a>
                                </td>
                            </tr>)
                        )}
                    </tbody>
                </table>
            </div>
        </div>
    );
}

export default File;