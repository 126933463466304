import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { FaEdit, FaTrashAlt } from "react-icons/fa";

import api from '../../Services/api';

function Church() {
    const [church, SetChurch] = useState([]);

    useEffect(() => {
        async function getChurch() {
            const response = await api.get('/church');
            SetChurch(response.data);
        }
        getChurch();
    }, []);

    return (
        <div className="container">
            <strong>Igrejas</strong>
            <div className="header">
                <Link to="/church/form">
                    <button>Adicionar</button>
                </Link>
            </div>
            <div className="painel">
                <table>
                    <thead>
                        <tr>
                            <th>Id</th>
                            <th>Título</th>
                            <th>Opções</th>
                        </tr>
                    </thead>
                    <tbody>
                        {church.map(church => (
                            <tr key={church.id}>
                                <td>{church.id}</td>
                                <td>{church.title}</td>
                                <td className="options">
                                    <a><FaEdit /></a>
                                    <a><FaTrashAlt /></a>
                                </td>
                            </tr>)
                        )}
                    </tbody>
                </table>
            </div>
        </div>
    );
}

export default Church;