import React, { useState } from 'react';
import DatePicker, { registerLocale } from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import pt from "date-fns/locale/pt-BR";


import Loading from "../../Components/Loading"
import api from '../../Services/api'

registerLocale("pt-BR", pt);

function FormCalendar({ history }) {
    const [startDate, setStartDate] = useState(new Date());
    const [description, setDescription] = useState('');

    const [loading, setLoading] = useState(0);

    async function hadleSubmit(e) {
        e.preventDefault();
        setLoading(1);
        try {
            await api.post("/calendar", {
                date: startDate.toLocaleDateString(),
                description
            });
            history.push('/calendar');
        } catch (e) {
            console.log(e.response);
            setLoading(0);
        }
    }

    return (
        <div className="container">
            <strong>Adicionar evento</strong>
            <div className="header">
            </div>
            <div className="painel">
                <form onSubmit={hadleSubmit}>
                    <div className="input-block">
                        <label htmlFor="date">Data</label>
                        <DatePicker
                            id="date"
                            name="date"
                            selected={startDate} 
                            onChange={date => setStartDate(date)} 
                            locale="pt-BR"
                            dateFormat="dd/MM/yyyy" 
                            required
                            showPopperArrow={false}
                        />
                       
                    </div>
                    <div className="input-block">
                        <label htmlFor="description">Descrição</label>
                        <textarea 
                            rows="8" 
                            name="description" 
                            id="description" 
                            value={description} 
                            required 
                            onChange={e => setDescription(e.target.value)}
                        ></textarea>
                    </div>
                    <button type="submit">Salvar</button>
                </form>
            </div>
            {loading ? <Loading /> : null}
        </div>
    );
}

export default FormCalendar;