import React from 'react';
import './style.css';

function Header(){
    return(
        <header>

        </header>
    );
}

export default Header;